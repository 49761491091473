import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { PrivateRoute } from '@emerald-works/react-auth'
import {
  I18nExample,
  FormExample,
  HomePage,
  SimpleReduxExample,
  AsyncReduxExample,
  CounterRequestResponseExample,
  CounterRequestResponseWithReduxExample,
  CounterRealtime,
  CounterRealtimeWithEffect,
  ChatExample,
  ChatContextReducer,
  StockTicker,
  StockTickerAdmin,
  TodoRealtime,
  DisconnectHandling
} from './pages'

const PrivateRoutePage = () => {
  return (
    <div>
      This is a private route sample page
    </div>
  )
}

const ApplicationRoutes = (props) => {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/i18n-example' element={<I18nExample />} />
      <Route path='/form-example' element={<FormExample />} />
      <Route path='/simple-redux-example' element={<SimpleReduxExample />} />
      <Route path='/async-redux-example' element={<AsyncReduxExample />} />
      <Route path='/counter-request-response' element={<CounterRequestResponseExample />} />
      <Route path='/counter-request-response-redux' element={<CounterRequestResponseWithReduxExample />} />
      <Route path='/todo-realtime' element={<TodoRealtime />} />
      <Route path='/counter-realtime' element={<CounterRealtime />} />
      <Route path='/counter-realtime-side-effects' element={<CounterRealtimeWithEffect />} />
      <Route path='/chat' element={<ChatExample />} />
      <Route path='/chat-context-reducer' element={<ChatContextReducer />} />
      <Route path='/stock-ticker' element={<StockTicker />} />
      <Route path='/stock-ticker-admin' element={<StockTickerAdmin />} />
      <Route path='/disconnect-handling' element={<DisconnectHandling />} />
      <Route path='/private-route' element={<PrivateRoute element={<PrivateRoutePage {...props} />} />} />
    </Routes>
  )
}

export default ApplicationRoutes
